<template>
  <div>
    <div class="card">
      <div class="title-box">
        <h4 class="sub-title">{{ $t('propTrade.createAccount.billingDetail.subTitle') }}</h4>
      </div>
      <el-form class="form-box" :model="form" :rules="formRules" ref="form">
        <div class="payment-method">
          <el-form-item prop="paymentMethod">
            <div class="payment-method-item" v-for="itme in calPaymentMethodList" :key="itme.key">
              <el-radio-group v-model="form.paymentMethod" @input="setPaymentDetailDefault" data-testid="paymentMethod">
                <el-radio :label="itme.key" :data-testid="itme.key">
                  {{ itme.name }}
                </el-radio>
              </el-radio-group>
              <el-form-item prop="crypto" v-if="itme.key === 'crypto' && form.paymentMethod === 'crypto'">
                <div class="payment-radio">
                  <el-radio-group v-model="form.crypto">
                    <PaymentRadio
                      v-for="(payment, index) in cryptoList"
                      :key="`ptCrypto${index}`"
                      :payment="payment"
                      @changePaymentDetail="changePaymentDetail"
                    ></PaymentRadio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item prop="ewallet" v-if="itme.key === 'ewallet' && form.paymentMethod === 'ewallet'">
                <div class="payment-radio">
                  <el-radio-group v-model="form.ewallet">
                    <PaymentRadio
                      v-for="(payment, index) in ewalletList"
                      :key="`ptEwallet${index}`"
                      :payment="payment"
                      :showName="false"
                      @changePaymentDetail="changePaymentDetail"
                    ></PaymentRadio>
                  </el-radio-group>
                </div>
              </el-form-item>
            </div>
          </el-form-item>
        </div>
        <div
          class="payment-detail-box"
          v-if="chooseAccountData && chooseAccountData.form && chooseAccountData.currentSetting"
        >
          <h4 class="sub-title">{{ $t('propTrade.createAccount.payment.paymentDetails') }}</h4>
          <div class="detail">
            <div>
              <p class="detail-text">{{ $t('propTrade.createAccount.billingDetail.item') }}</p>
              <ul class="detail-list">
                <li>
                  <span class="lable">{{ $t('propTrade.createAccount.billingDetail.accountType') }} :</span>
                  <span class="value">{{
                    $t(`propTrade.accountType.${ACCOUNT_TYPE_REV[chooseAccountData.form.accountType]}`)
                  }}</span>
                </li>
                <li>
                  <span class="lable">{{ $t('propTrade.createAccount.billingDetail.accountBalance') }} :</span>
                  <span class="value"
                    >{{ new Intl.NumberFormat().format(chooseAccountData.form.accountBalance) }}
                    {{ chooseAccountData.form.currency }}</span
                  >
                </li>
                <li>
                  <span class="lable">{{ $t('propTrade.createAccount.billingDetail.targetProfit') }} :</span>
                  <span class="value">{{ chooseAccountData.form.tradingPlatform }}</span>
                </li>
              </ul>
            </div>
            <p class="detail-text">
              {{ chooseAccountData.form.currency }} {{ chooseAccountData.currentSetting.fee | fixedDecimal(2) }}
            </p>
          </div>
          <div class="promo-code">
            <p class="promo-code-lable">{{ $t('propTrade.createAccount.billingDetail.promoCode') }}</p>
            <el-form-item prop="promoCode">
              <div class="promo-code-form">
                <div class="input-box">
                  <TextInput
                    type="text"
                    v-model="promoCode"
                    dataTestId="promoCode"
                    inputType="outline"
                    :customClasses="`promo-code-input ${promoCodeStatus} ${lang}`"
                    suffix-icon="icon-check"
                    :disabled="promo.lastSuccesss.status"
                  >
                  </TextInput>
                  <div class="error-message" v-if="promoCodeStatus === 'error'">
                    {{ promoErrorMessage }}
                  </div>
                </div>
                <Button
                  buttonType="default"
                  customClasses="promo-code-button"
                  dataTestId="apply"
                  height="40"
                  :disabled="promo.lastSuccesss.status"
                  @click="applyPromoCodeHandler(currentSettingId)"
                >
                  <div>{{ $t('propTrade.button.apply') }}</div>
                </Button>
              </div>
            </el-form-item>
          </div>
          <ul class="fee">
            <li>
              <div class="lable">
                <span>{{ $t('propTrade.createAccount.payment.subtotal') }}</span>
              </div>
              <div class="value">
                <span class="currency">{{ chooseAccountData.form.currency }}</span>
                <span>{{ chooseAccountData.currentSetting.fee | fixedDecimal(2) }}</span>
              </div>
            </li>
            <li v-show="promo.lastSuccesss.status && discount">
              <div class="lable">
                <span>{{ $t('propTrade.createAccount.payment.discount') }}</span>
              </div>
              <div class="value">
                <span class="currency">-{{ chooseAccountData.form.currency }}</span>
                <span>{{ discount | fixedDecimal(2) }}</span>
              </div>
            </li>
            <li>
              <div class="lable">
                <span>{{ $t('propTrade.createAccount.payment.grandTotalRegistrationFee') }}</span>
                <InfoPopover popoverWidth="220">
                  <template v-slot:popoverContent>
                    <div>{{ $t('propTrade.createAccount.payment.totalRegistrationFeeInfo') }}</div>
                  </template>
                </InfoPopover>
              </div>
              <div class="value total">
                <span class="currency">{{ chooseAccountData.form.currency }}</span>
                <span>{{ finalFee | fixedDecimal(2) }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="btn-box">
          <div class="back-button">
            <Button
              buttonType="default"
              customClasses="action-button"
              outline
              dataTestId="back"
              @click="goBackToChooseAccount"
            >
              <div>{{ $t('propTrade.button.back') }}</div>
            </Button>
          </div>
          <div class="confirm-button">
            <Button buttonType="default" customClasses="action-button" dataTestId="confirm" @click="submit('form')">
              <div>{{ $t('propTrade.button.proceedPayment') }}</div>
            </Button>
          </div>
        </div>
      </el-form>
    </div>

    <div v-html="bankHTML"></div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import TextInput from '@/components/common/TextInput.vue';
import InfoPopover from '@/components/common/InfoPopover';
import PaymentRadio from './PaymentRadio';
import cpsDepositListingMixin from '@/mixins/page/deposit/cpsDepositListing';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import promoCodeMixin from '@/mixins/page/propTrade/promoCode';
import { apiPropTradingProceedPayment, apiPropTradingProceedPaymentCPS, apiCheckPromoAvailable } from '@/resource';
import { ACCOUNT_TYPE_REV, PROMO_CODE_DISCOUNT_ALL_PRICE } from '@/constants/propTrading';
import { isSuccess } from '@/util/restApi';
import propTradeCommonFilters from '@/mixins/page/propTrade/commonFilters';
import mixin from '@/mixins';
import { MessageBox } from 'element-ui';

export default {
  name: 'BillingDetail',
  components: { Button, TextInput, InfoPopover, PaymentRadio },
  mixins: [cpsDepositListingMixin, cpsMixin, promoCodeMixin, propTradeCommonFilters, mixin],
  props: {
    chooseAccountData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ACCOUNT_TYPE_REV,
      form: {
        paymentMethod: 'crypto',
        crypto: null,
        ewallet: null,
        promoCode: ''
      },
      formRules: {
        paymentMethod: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.createAccount.billingDetail.subTitle')
            }),
            trigger: 'change'
          }
        ]
      },
      paymentDetail: {},
      avialableCurrencies: ['EUR', 'USD', 'GBP', 'CAD']
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    calPaymentMethodList() {
      const paymentMethodList = [
        {
          name: this.$t('propTrade.createAccount.billingDetail.creditcard'),
          key: 'creditcard'
        },
        {
          name: this.$t('propTrade.createAccount.billingDetail.crypto'),
          key: 'crypto'
        }
      ];

      if (this.displayEwalletList) {
        paymentMethodList.push({
          name: this.$t('propTrade.createAccount.billingDetail.ewallet'),
          key: 'ewallet'
        });
      }

      return paymentMethodList;
    },
    currentSettingId() {
      return this.chooseAccountData.currentSetting?.id;
    },
    customFilterCpsDepositList() {
      return this.cpsDepositList.filter(payment => {
        if (!payment?.merchant_variable?.enable_pt) return false;

        const uniqueCurrency = payment.unique_currency;
        if (uniqueCurrency?.length) {
          return uniqueCurrency.map(i => i.currency_code).includes(this.chooseAccountData.currentSetting?.currency);
        }

        return (
          this.chooseAccountData.currentSetting?.currency === payment.currency_code ||
          payment.payment_method === 'T00400'
        );
      });
    },
    cryptoList() {
      const cryptoList = this.customFilterCpsDepositList.filter(payment =>
        this.permittedCpsCryptoPaymentMethods.includes(payment.payment_method)
      );

      if (!this.form.crypto && cryptoList.length > 0) {
        const payment = cryptoList[0];

        this.form.crypto = payment.merchant_variable?.label || payment.merchant_variable?.description_title;

        this.changePaymentDetail(payment);
      }

      return cryptoList;
    },
    ewalletList() {
      return this.customFilterCpsDepositList.filter(payment =>
        this.permittedCpsEWalletPaymentMethods.includes(payment.payment_method)
      );
    },
    displayEwalletList() {
      return !!this.ewalletList.length;
    },
    discount() {
      if (this.promo.lastSuccesss.result) {
        return this.calcDiscount(this.promo.lastSuccesss.result, this.chooseAccountData.currentSetting.fee);
      }

      return 0;
    },
    finalFee() {
      const res = Number(this.chooseAccountData.currentSetting.fee) - Number(this.discount);
      return res > 0 ? res : 0;
    },
    proceedPaymentRequestBody() {
      return {
        feeId: this.currentSettingId,
        leverage: this.chooseAccountData.form.leverage,
        code: this.promo.lastSuccesss.code,
        isOpenInstantAccount: this.chooseAccountData.form.isOpenInstantAccount
      };
    }
  },
  mounted() {
    // get cps deposit channels list
    this.getCPSDepositChannels();
  },
  methods: {
    goToDestination() {
      this.$router.push({ name: 'home' });
    },
    goBackToChooseAccount() {
      if (this.promo.lastSuccesss.status) {
        MessageBox.confirm(this.$t('propTrade.createAccount.billingDetail.cancelPromoCodeInfo'), '', {
          customClass: 'pt-message-box',
          confirmButtonText: this.$t('propTrade.button.yes'),
          cancelButtonText: this.$t('propTrade.button.cancel'),
          type: 'info'
        }).then(() => {
          this.$emit('redirectCurrentPage', 'chooseAccount');
        });
      } else {
        this.$emit('redirectCurrentPage', 'chooseAccount');
      }
    },
    async proceedPaymentCPS() {
      // refactor: extract to utils(or other place)
      const determineActualCurrencyCode = currency => {
        return currency === 'USC' ? 'USD' : currency;
      };

      // refactor: extract to utils(or other place)
      const getCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.currency_number;
        }
        return item.currency_number;
      };

      // refactor: extract to utils(or other place)
      const getActualCurrencyNumber = (item, currency) => {
        if (item.unique_currency && currency) {
          const uniqueCurrencyConfig = item.unique_currency;
          let configObject = {};
          uniqueCurrencyConfig.forEach(config => {
            if (config.actual_currency_code === currency) {
              configObject = { ...config };
            }
          });
          return configObject.actual_currency_number;
        }
        return item.actual_currency_number;
      };

      try {
        const attachVariableObj = {};

        const attachVariables = this.paymentDetails.attach_variable
          ? this.paymentDetails.attach_variable.filter(f => f.length > 0)?.[0]
          : '';

        if (attachVariables) {
          attachVariables.map(a => {
            attachVariableObj[a.key] = a.value;
          });
        }

        const requestBody = {
          cpsCode: this.paymentDetails.payment_method,
          orderCurrency: this.paymentDetails.unique_currency
            ? getCurrencyNumber(this.paymentDetails, determineActualCurrencyCode(this.chooseAccountData.form.currency))
            : this.paymentDetails.currency_number,
          actualCurrency: this.paymentDetails.unique_currency
            ? getActualCurrencyNumber(
                this.paymentDetails,
                determineActualCurrencyCode(this.chooseAccountData.form.currency)
              )
            : this.paymentDetails.actual_currency_number,
          mandatory: this.paymentDetails.attach_variable
            ? this.paymentDetails.attach_variable.filter(f => f.length > 0)?.[0].toString()
            : '',
          attachVariables: JSON.stringify(attachVariableObj),
          ...this.proceedPaymentRequestBody
        };

        const result = await apiPropTradingProceedPaymentCPS(requestBody, this.token);

        return result;
      } catch (e) {
        console.error(e);
      }

      return null;
    },
    async proceedPayment() {
      try {
        const result = await apiPropTradingProceedPayment(this.proceedPaymentRequestBody, this.token);

        return result;
      } catch (e) {
        console.error(e);
      }
    },
    async proceedPaymentSuccessResultHandle(result) {
      const data = result?.data?.data;
      if (data && data === PROMO_CODE_DISCOUNT_ALL_PRICE) {
        await this.$store.dispatch('propTrading/actionGetEligiblePropTrading');

        this.$router.push({ name: 'propTradingDashboard' });

        return true;
      }

      return false;
    },

    submit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (!this.promoCodeCheck()) {
            return;
          }

          if (this.form.paymentMethod === 'creditcard') {
            const result = await this.proceedPayment();

            if (isSuccess(result.data)) {
              const isHandled = await this.proceedPaymentSuccessResultHandle(result);

              if (isHandled) return;

              const data = result.data.data;

              if (data) {
                this.$emit('redirectCurrentPage', 'billingCreditCard', data);
              }
            }
          } else {
            const result = await this.proceedPaymentCPS();

            const isHandled = await this.proceedPaymentSuccessResultHandle(result);

            if (isHandled) return;

            this.cpsHandling(result);
          }
        }
      });
    },
    changePaymentDetail(data) {
      // this.paymentDetail = data;
      this.$store.dispatch('cps/actionSetPayment', data);
    },
    setPaymentDetailDefault(value) {
      if (!['crypto', 'ewallet'].includes(value)) return;
      this.form[value] =
        this[`${value}List`][0].merchant_variable?.label ||
        this[`${value}List`][0].merchant_variable?.description_title;
      this.changePaymentDetail(this[`${value}List`][0]);
    },
    applyPromoCodeHandler() {
      this.applyPromoCode(this.currentSettingId);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ {
  .el-radio {
    color: $black;
  }
  .el-radio__inner {
    width: 16px;
    height: 16px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    content: url(~@/assets/images/openAccount/radio02.png);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $black;
  }
}

/deep/.payment-radio {
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2px;
  }
  .el-radio-button {
    display: block;
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }

  .el-radio-button__inner {
    width: 100%;
    border: 1px solid $pug-pray-border;
    border-radius: 6px;
    padding: 0;
  }
  &-item-box {
    width: 100%;
    height: 56px;
    padding: 12px 40px;
    text-align: center;
  }
  .payment-img {
    height: 32px;
    display: inline-block;
    img {
      height: 100%;
    }
  }
  .payment-name {
    font-size: 14px;
    color: $black;
    width: calc(100% - 32px);
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: $pug-blue-radio-button-active-bg;
    border: 1px solid $pug-blue;
    box-shadow: none;
    .payment-name {
      color: $pug-blue;
      font-weight: bold;
    }
  }
}
.payment-method {
  &-item {
    display: block;
    border-bottom: 1px solid $pug-gray-disabled-bg;
    padding: 4px 16px;
  }
}

.payment-detail-box {
  font-size: 16px;
  color: $black;
  margin-top: -15px;
  .detail {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    &-text {
      margin-bottom: 8px;
    }
  }
  .detail-list {
    margin-bottom: 8px;
    li {
      font-size: 12px;
      color: $pug-gray-pending;
      line-height: 24px;
    }
    .lable {
      display: inline-block;
      margin-right: 4px;
    }
  }
  .promo-code {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid $pug-gray-disabled-bg;
    margin-bottom: 16px;
    &-lable {
      margin-bottom: 8px;
    }
    &-form {
      display: flex;
    }
    &-input {
      @include rtl-sass-prop(margin-right, margin-left, 10px);
      /deep/ .el-input__inner {
        height: 40px;
        padding: 8px 20px;
      }
      &.success {
        [dir='ltr'] * /deep/ .el-input__inner {
          padding-right: 32px;
        }
        [dir='rtl'] * /deep/ .el-input__inner {
          padding-left: 32px;
        }
        /deep/.icon-check {
          &::before {
            display: inline-block;
          }
        }
        &.ar {
          /deep/ .icon-check {
            &::before {
              left: 4px;
              right: auto;
            }
          }
        }
      }
      /deep/.icon-check {
        &::before {
          content: '';
          background: url(~@/assets/images/propTrading/check.png) no-repeat center;
          background-size: cover;
          width: 19px;
          height: 19px;
          display: none;
          top: 6px;
          right: 4px;
          left: auto;
          position: relative;
        }
      }
    }
    .input-box {
      position: relative;
      flex: 1 1 80%;
    }
    .error-message {
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 100%;
      color: $pug-red;
    }
    &-button {
      flex: 1 1 20%;
      // width: 88px;
      /deep/.el-button {
        font-size: 16px;
        font-weight: 800;
        padding: 8px 18px;
      }
    }
  }
}
.fee {
  margin-bottom: 60px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  .lable {
    font-size: 14px;
    color: $pug-black;
  }
  .value {
    font-size: 14px;
    color: $pug-black;
    text-align: right;
  }
  .total {
    font-size: 14px;
    font-weight: bold;
  }
  .currency {
    margin-right: 4px;
  }
}
.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.back-button {
  width: 100%;
  order: 2;
}
.confirm-button {
  width: 100%;
  order: 1;
}
.action-button {
  font-size: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  /deep/.payment-radio {
    .el-radio-group {
      margin-bottom: -16px;
    }
    .el-radio-button {
      margin-right: 15px;
      margin-bottom: 18px;
      width: unset;
    }
    &-item-box {
      width: 185px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .payment-method {
    &-item {
      padding: 14px 16px;
    }
  }

  .payment-detail-box {
    margin-top: 0;
    .detail-list li {
      font-size: 14px;
    }
    .promo-code {
      &-input {
        /deep/ .el-input__inner {
          width: 255px;
        }
      }
    }
  }

  .fee {
    margin-bottom: 80px;
    .lable {
      font-size: 16px;
    }
    .value {
      font-size: 16px;
    }
    .total {
      font-size: 20px;
    }
  }
  .btn-box {
    gap: 24px;
  }
  .back-button {
    width: 200px;
    height: 60px;
    order: 1;
  }
  .confirm-button {
    width: 256px;
    height: 60px;
    order: 2;
  }
  .action-button {
    height: 60px;
  }
}
</style>

<style lang="scss">
.pt-message-box {
  .el-button {
    text-transform: none;
    border-radius: 10px;
    font-size: 18px;
    padding: 12px;
    font-weight: 800;
    background-color: $pug-blue;
    border: 1px solid $pug-blue;
    color: $pug-white;
    word-break: break-word;
    white-space: initial;

    > span {
      display: block;
      padding: 4px;
    }
    &:not(.el-button--primary) {
      color: $pug-black;
      border: 1px solid $pug-gray;
      background-color: $pug-white;
    }
  }
}
</style>
