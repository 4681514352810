/**
 * For CPS Deposit Listing Ordering
 * (CPS Module)
 */

import { countryCodeEnum } from '@/constants/country'

export const cpsDepositListingIndex = [
  // Country Specific Sample
  // {
  //   id: countryCodeEnum.INDIA,
  //   order: ['T00600_055', 'GT00400'],
  // },
  {
    id: 'global',
    order: [
      'T00312_020',
      'T00600_063', // Webpayment-ZAR / Webpayment-NGN / Webpayment-KES
      'T00100_052', // Perfect Money (E-WALLET)
      'T00400', // Crypto Eth (CRYPTOCURRENCY TAG)
      'T00600_055', // India Instant Bank Transfer
    ],
  },
  {
    id: countryCodeEnum.SOUTH_KOREA,
    order: ['T00600_118'],
  },
  {
    id: countryCodeEnum.CANADA,
    order: ['T00600_115'],
  },
  {
    id: countryCodeEnum.NETHERLANDS,
    order: ['T00600_083'],
  },
  {
    id: countryCodeEnum.TAIWAN,
    order: ['T00600_107'],
  },
]
