<template>
  <el-form class="form-box" :model="form" :rules="formRules" ref="form">
    <el-row :gutter="40">
      <el-col :sm="12" :xs="24">
        <el-form-item prop="tradingPlatform">
          <Selection
            v-model="form.tradingPlatform"
            :placeholder="$t('common.formValidation.answerReq')"
            :options="tradingPlatformOptions"
            :disabled="disabled"
            filterable
            selectType="underline"
            dataTestId="platform"
          >
            <template #title>
              {{
                $t('register.accOpeningVerification.createLiveAccount.choose', {
                  dynamic: $t('register.accOpeningVerification.createLiveAccount.tradingPlatform')
                })
              }}
            </template>
          </Selection>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="accountType">
          <Cascader
            :title="
              $t('register.accOpeningVerification.createLiveAccount.choose', {
                dynamic: $t('register.accOpeningVerification.createLiveAccount.accountType')
              })
            "
            v-model="accountTypeSelection"
            :options="accountTypeList"
            :disabled="disabled"
          ></Cascader>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="40">
      <el-col :sm="12" :xs="24">
        <el-form-item prop="currency">
          <Selection
            v-model="form.currency"
            :placeholder="$t('common.formValidation.answerReq')"
            :options="currencyOptions"
            :disabled="disabled"
            filterable
            selectType="underline"
            dataTestId="currency"
          >
            <template #title>
              {{
                $t('register.accOpeningVerification.createLiveAccount.choose', {
                  dynamic: $t('register.accOpeningVerification.createLiveAccount.accountCurrency')
                })
              }}
            </template>
          </Selection>
        </el-form-item>
      </el-col>
      <el-col class="mb-4" v-if="minDeposit || isIslamicAccountType">
        <div class="info-box">
          <div class="info-img">
            <img src="@/assets/images/info-icon.png" class="label-icon" />
          </div>
          <div class="info-box-text">
            <div v-if="minDeposit">
              <span>{{ $t('openAdditAcc.minDeposit') }}</span>
              <span
                ><span class="text-higlight">{{ minDeposit }}</span> {{ form.currency }}</span
              >
            </div>
            <div v-if="isIslamicAccountType">
              <div v-if="isIslamic">
                <strong>{{ $t('openAdditAcc.importantNote') }}</strong>
                <span>{{ $t('openAdditAcc.islamicTnc') }}</span>
              </div>
              <div v-else>
                <strong>{{ $t('openAdditAcc.importantNote') }}</strong>
                <span>
                  <i18n path="openAdditAcc.nonIslamicTnc">
                    <template v-slot:link>
                      <a
                        href="https://helpcenter.puprime.com/hc/en-001/articles/5551547411983-When-would-an-administration-fee-be-charged-in-my-Islamic-Trading-Account"
                        target="_blank"
                        >{{ $t('openAdditAcc.here') }}</a
                      >
                    </template>
                  </i18n>
                </span>
              </div>
              <div>
                <span>{{
                  $t('register.accOpeningVerification.createLiveAccount.islamicTooltip', {
                    platform: $config.info.fullName
                  })
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col>
        <el-form-item class="mb-3" prop="checkStatus" :inline-message="true">
          <div class="checkBox pug-checkbox">
            <div class="d-flex align-items-center">
              <el-checkbox
                id="checkbox"
                name="checkStatus"
                v-model="form.checkStatus"
                :disabled="disabled"
                data-testid="checkStatus"
              >
              </el-checkbox>
              <i18n path="openAdditAcc.byTicking" tag="div" class="tnc">
                <template v-slot:tnc>
                  <p class="showTnc" @click="showTNC = !showTNC" data-testid="showTNC">{{ $t('openAdditAcc.tnc') }}</p>
                </template>
              </i18n>
            </div>
            <ul
              class="tnc-details"
              v-show="showTNC"
              v-html="
                $t($config.openLiveAccount.getAgreeCondition(regulator), {
                  platform: $config.info.fullName,
                  regulatorUrl: GLOBAL_REFERRAL_SITE
                })
              "
            ></ul>
          </div>
        </el-form-item>
        <div v-if="showCopyTradingTnc">
          <el-form-item class="mb-3" prop="checkCopyTradingTnc" :inline-message="true">
            <div class="checkBox pug-checkbox">
              <div class="d-flex align-items-center">
                <el-checkbox
                  id="checkbox"
                  name="checkCopyTradingTnc"
                  v-model="form.checkCopyTradingTnc"
                  :disabled="disabled"
                  data-testid="checkCopyTradingTnc"
                >
                </el-checkbox>
                <i18n path="openAdditAcc.byTickingCopyTrading" tag="div" class="tnc">
                  <template v-slot:tnc>
                    <a class="showTnc" target="_blank" :href="copyTradingHref" data-testid="showCopyTradingTnc">{{
                      $t('openAdditAcc.tnc')
                    }}</a>
                  </template>
                </i18n>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-col>
    </el-row>

    <div class="action-area mt-5">
      <!-- <Button
        buttonType="default"
        customClasses="action-button"
        outline
        dataTestId="back"
        @click="goToPersonalInformation"
      >
        <div>{{ $t('register.btn.back') }}</div>
      </Button> -->
      <Button buttonType="default" customClasses="action-button" dataTestId="confirm" @click="submit('form')">
        <div>{{ $t('common.button.confirm') }}</div>
      </Button>
    </div>

    <InfoDialog :visible.sync="showResDialog" :title="resDialogTitle" :infoType="resInfoType" @closCbDialog="goToStep">
      <div v-html="resDialogContent"></div>

      <template #actions>
        <Button buttonType="default" v-if="autoPass" class="mb-3" height="unset" @click="goToDeposit">
          {{ $t('verificationStatus.kyc.depositNow') }}
        </Button>
        <Button buttonType="default" :outline="autoPass" class="mb-3" height="unset" @click="goToIdentityVerification">
          {{ $t('register.btn.completeID') }}
        </Button>
        <!-- <Button buttonType="default" :outline="true" v-if="!autoPass" @click="goToStep">
          {{ $t('common.button.ok') }}
        </Button> -->
      </template>
    </InfoDialog>
  </el-form>
</template>

<script>
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import Cascader from '@/components/common/Cascader.vue';
import { getUserStep, getNextRegisterStep, getRegisterStep, getStatus } from '@/util/register';
import { checkAgreedTNC, checkAgreedCopyTradingTNC } from '@/util/validation';
import { REGISTERROUTER, DEPOSIT_PATHNAME } from '@/constants/route';
import { statusConfig } from '@/constants/status';
import { apiGetData, apiProcess } from '@/resource/register';
import { apiGetAutoStartTradingThreshold } from '@/resource';
import { mapState, mapActions } from 'vuex';
import { islamicCountry } from '@/constants/islamicCountry';
import { highRiskCountry } from '@/constants/highRiskCountry';

export default {
  name: 'CreateLiveAccountDefault',
  components: { InfoDialog, Button, Selection, Cascader },
  data() {
    return {
      form: {
        tradingPlatform: null,
        accountType: null,
        currency: null,
        checkStatus: false,
        checkCopyTradingTnc: false
      },
      formRules: {
        tradingPlatform: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.tradingPlatform')
            }),
            trigger: 'change'
          }
        ],
        accountType: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.accountType')
            }),
            trigger: 'change'
          }
        ],
        currency: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.accountCurrency')
            }),
            trigger: 'change'
          }
        ],
        checkStatus: [{ validator: checkAgreedTNC, trigger: 'change' }],
        checkCopyTradingTnc: [{ validator: checkAgreedCopyTradingTNC, trigger: 'change' }]
      },
      dobList: [],
      showTNC: false,
      showResDialog: false,
      resInfoType: 'success',
      autoPass: true,
      resDialogTitle: this.$t('register.accOpeningVerification.createLiveAccount.successInfo.title'),
      resDialogContent: this.$t('register.accOpeningVerification.createLiveAccount.successInfo.content'),
      threshold: {},
      accountTypeSelection: null,
      copyTradingHref: ''
    };
  },
  watch: {
    'form.accountType': {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (oldVal) this.form.currency = '';
      }
    },
    accountTypeSelection: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (Array.isArray(newVal)) {
          this.form.accountType = newVal[newVal.length - 1];
        } else {
          this.form.accountType = newVal;
        }
      }
    }
  },
  computed: {
    ...mapState('register', ['currStep']),
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    disabled() {
      return this.currStep > getRegisterStep(this.$route.name);
    },
    lang() {
      return this.$store.state.common.lang;
    },
    tradingPlatformOptions() {
      const tradingPlatformData = this.$config.openLiveAccount.choosePlatform(this.regulator);
      // set default platform
      this.form.tradingPlatform = tradingPlatformData[0].value;

      return tradingPlatformData.map(item => ({
        label: this.$t(item.label),
        value: item.value
      }));
    },
    accountTypeList() {
      let typeList = this.$config.openLiveAccount.registerChooseType;

      const result = typeList(this.regulator).filter(f => {
        const isParentValid = this.isValidByCountry(f, this.countryCode);

        if (f.children && f.children.length > 0) {
          f.children = this.filterChildren(f.children, this.countryCode);
        }

        return isParentValid || (f.children && f.children.length > 0);
      });

      // set default accountType
      if (this.accountTypeSelection == null && result.length > 0) {
        if (result.length > 0 && result[0].hasOwnProperty('children') && result[0].children.length > 0) {
          this.form.accountType = result[0].children[0].value;
          this.accountTypeSelection = result[0].children[0].value;
        } else {
          this.form.accountType = result[0].value;
          this.accountTypeSelection = result[0].value;
        }
      }

      return result;
    },
    currencyOptions() {
      const result = this.$config.openLiveAccount.chooseCurrency(this.regulator, this.form.accountType);

      // check current choose accountType
      this.form.currency = result[0].value;

      return result;
    },
    userStep() {
      return getUserStep(this.$route.name);
    },
    nextRegisterStep() {
      return getNextRegisterStep(this.$route.name);
    },
    minDeposit() {
      try {
        return this.threshold[this.form.accountType][this.form.currency].amount;
      } catch (e) {
        return null;
      }
    },
    showCopyTradingTnc() {
      this.copyTradingHref = 'https://www.puprime.com/copy_trading_terms_and_conditions_cp';

      if (highRiskCountry.includes(this.countryCode)) {
        this.copyTradingHref = 'https://www.puprime.net/copy_trading_terms_and_conditions_cp_net';
      }

      if (this.form.tradingPlatform === 'MTS') {
        return true;
      } else {
        return false;
      }
    },
    isIslamic() {
      return islamicCountry.includes(this.countryCode) ? true : false;
    },
    isIslamicAccountType() {
      const islamicAccountType = ['swapFreeStandardSTP', 'ISLAMIC_STP_CENT', 'swapFreeRawECN', 'ISLAMIC_ECN_CENT'];

      return islamicAccountType.includes(this.form.accountType) ? true : false;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('register', ['actionStep', 'actionAccountAuditStatus']),
    init() {
      this.getData();
      this.getAutoStartTradingThreshold();
    },
    getData() {
      apiGetData(this.userStep).then(response => {
        if (response.data.code === 0 && response.data?.data) {
          let { accountType, ...restData } = response.data.data;
          let reqBody = {
            ...restData,
            accountType: accountType ? accountType.value : this.accountTypeList[0].value,
            checkCopyTradingTnc: false
          };

          if (accountType) {
            this.accountTypeSelection = accountType.value;
          }

          this.form = reqBody;
        }

        if (this.form.tradingPlatform && this.form.accountType && this.form.currency) {
          this.form.checkStatus = true;

          if (this.form.tradingPlatform === 'MTS') {
            this.form.checkCopyTradingTnc = true;
          }
        }
      });
    },
    getAutoStartTradingThreshold() {
      apiGetAutoStartTradingThreshold().then(resp => {
        resp.data.forEach(element => {
          if (!this.threshold[element.alias])
            Object.assign(this.threshold, {
              [element.alias]: {
                [element.currency]: {
                  amount: element.threshold
                }
              }
            });
          else if (!this.threshold[element.alias][element.currency])
            Object.assign(this.threshold[element.alias], {
              [element.currency]: {
                amount: element.threshold
              }
            });
        });
      });
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          apiProcess({ step: this.userStep, ...this.form }).then(resp => {
            if (resp.data.code == 0) {
              this.actionStep(this.nextRegisterStep);
              this.actionAccountAuditStatus().then(accountStatus => {
                if (getStatus(accountStatus) !== statusConfig.completed) {
                  this.autoPass = false;
                  this.resInfoType = 'pending';
                  this.resDialogTitle = this.$t('register.accOpeningVerification.createLiveAccount.generalInfo.title');
                  this.resDialogContent = this.$t(
                    'register.accOpeningVerification.createLiveAccount.generalInfo.content',
                    {
                      platform: this.$config.info.fullName
                    }
                  );
                }
                this.showResDialog = true;
              });
            }
          });
        }
      });
    },
    goToStep() {
      this.$router.push(REGISTERROUTER.REGISTER);
    },
    goToDeposit() {
      this.$router.push(DEPOSIT_PATHNAME);
    },
    goToIdentityVerification() {
      this.$router.push(REGISTERROUTER.IDENTITY_VERIFICATION);
    },
    goToPersonalInformation() {
      this.$router.push(REGISTERROUTER.PERSONAL_INFORMATION);
    },
    isValidByCountry(obj, countryCode) {
      if (obj.restrictCountries) {
        return !obj.restrictCountries.includes(countryCode);
      }
      return true;
    },
    filterChildren(children, countryCode) {
      return children.filter(child => this.isValidByCountry(child, countryCode));
    }
  }
};
</script>

<style lang="scss" scoped>
.checkBox {
  .tnc-details {
    margin-top: 10px;
  }

  /deep/ .el-checkbox {
    display: contents;
  }

  /deep/ .tnc {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    @include rtl-sass-prop(margin-left, margin-right, 15px);

    .showTnc {
      display: inline-block;
      color: $pug-blue;
      cursor: pointer;
    }
  }
  /deep/ ul {
    @include rtl-sass-prop(padding-left, padding-right, 40px);

    li {
      font-size: 14px;
      line-height: 28px;
      list-style-type: decimal;
      a {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}

.action-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.action-button {
  width: 160px;
  height: 56px;
}

@media (max-width: 770px) {
  .action-area {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .action-button {
    width: 100%;
  }
}

.text-higlight {
  color: $pug-blue;
}

.mr-2 {
  margin-right: 10px;
}

.info-box {
  display: flex;
  border-radius: 8px;
  background-color: #ebf4ff;
  padding: 14px 12px;
  align-items: center;

  /deep/ strong {
    font-weight: bold;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.info-img {
  margin-right: 10px;
  flex-shrink: 0;
  align-self: flex-start;
}

.info-box-text {
  div + div {
    margin-top: 20px;
  }
}
</style>
